import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import './index.css'

const pageStyles = {
  padding: 0,
  margin: 0,
  fontFamily: "Roboto, sans-serif, serif",
}

const contentStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}

const bottomStyle = {
  width: '100%',
  backgroundColor: 'black',
  height: '40px',
  position: 'absolute',
  bottom: 0,
}

// markup
const IndexPage = ({ data }) => {
  return (
    <main style={pageStyles}>
      <title>Welcome to Sponk</title>
      <div style={contentStyle}>
        <div>
          <StaticImage  placeholder="tracedSVG"  layout="constrained" src='../images/logo_head_320.png' alt="Sponk" />
        </div>
      </div>
      <div style={bottomStyle}>
        <div style={{ textAlign: 'center', fontSize: '11px', textTransform: 'uppercase', letterSpacing: -0.5, lineHeight: '40px', color: "white", fontWeight: 100}}>
          <span>Copyright © 2021 Sponk</span>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
